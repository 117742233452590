import vueCompileHtml from 'system/vue-compile-html'
import StreamAction from './stream-actions'

export default function turboVueAdapter (rootApp, createVueAppHook) {
  let vueApp = rootApp
  let skipRecreateVue

  const recreateApp = () => {
    if (!skipRecreateVue) {
      skipRecreateVue = true
      vueApp.unmount()
      vueApp = createVueAppHook()
    }

    setTimeout(() => { skipRecreateVue = false }, 120)
  }

  document.addEventListener('turbo:render', () => {
    recreateApp()
  })

  document.addEventListener('turbo:load', () => {
    recreateApp()
  })

  // ===== Handle turbo frame
  document.addEventListener('turbo:before-frame-render', (event) => {
    if (event.target.tagName === 'TURBO-FRAME') {
      const { attributes } = event.target
      if (attributes.getNamedItem('self_refresh')) {
        skipRecreateVue = true
      }

      event.preventDefault()
      const frame = event.detail.newFrame

      const vNode = vueCompileHtml(frame.innerHTML)
      frame.innerHTML = ''
      frame.append(...vNode.children)

      event.detail.resume()
    }
  })

  document.addEventListener('turbo:frame-missing', (event) => {
    const { detail: { response, visit } } = event;
    event.preventDefault();
    visit(response.url);
  })

  // ===== Handle turbo stream
  document.addEventListener('turbo:before-stream-render', (event) => {
    const stream = event.target
    const actionFunction = StreamAction[stream.action]
    if (actionFunction) {
      event.preventDefault()
      actionFunction.call(stream)
    }
  })
}
