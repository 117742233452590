export default {
  beforeMount (el, { value: linkExt }) {
    let generateLink = () => `${window.location.pathname}.${linkExt + window.location.search}`

    let openLinkInNewTab = (link) => {
      let win = window.open(link, '_blank');
      win.focus();
    }

    el.addEventListener('click', (e) => {
      openLinkInNewTab(generateLink(e))
    })
  }
}
