export default {
  template: '<slot v-bind="this"/>',
  data () {
    return {
      storageKey: 'cookieBanner',
      value: 'on'
    }
  },

  computed: {
    isShow () {
      return this.value != 'off'
    }
  },

  mounted () {
    this.value = localStorage.getItem(this.storageKey)
  },

  methods: {
    accept () {
      localStorage.setItem(this.storageKey, 'off')
      this.value = localStorage.getItem(this.storageKey)
    }
  }
}
