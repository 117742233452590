export default {
  beforeMount (el, binding) {
    const goal = binding.value

    el.addEventListener('click', (e) => {
      if (typeof window.ym === 'function') {
        window.ym(window.ymId, 'reachGoal', goal)
        console.info('[YM]', 'reachGoal', goal)
      } else {
        console.warn('[YM DISABLED]', 'reachGoal', goal)
      }
    })
  }
}
