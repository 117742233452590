import kebabCase from 'lodash/kebabCase'
import appTooltip from '@components/ui/app-tooltip.vue'
import Loader from '@components/ui/loaders/app-loader.vue'
import appInputBox from '@components/ui/inputs/shared/app-input-box.vue'
import appPhone from '@components/ui/inputs/app-phone.vue'
import appCode from '@components/ui/inputs/app-code.vue'
import appInput from '@components/ui/inputs/app-input.vue'
import appCheckbox from '@components/ui/inputs/app-checkbox.vue'
import inputCheckbox from '@components/ui/inputs/input-checkbox.vue'
import smsClarification from '@components/misc/sms-clarification.vue'
import acceptLegalDocuments from '@components/misc/accept-legal-documents.vue'
import toastr from '@components/ui/toastr'
import sendNewSms from '@components/pages/users/send-new-sms'
import passwordChecker from '@components/pages/users/password-checker.vue'
import appModal from '@components/modals/main_template/app-modal.vue'
import cookieBanner from '@components/misc/cookie-banner'
import { vMaska } from 'maska'

export default function deviseContextRegister (app) {
  app.component('app-loader', Loader)
  app.component('app-tooltip', appTooltip)
  app.component('app-input-box', appInputBox)
  app.component('app-phone', appPhone)
  app.component('app-code', appCode)
  app.component('app-input', appInput)
  app.component('app-checkbox', appCheckbox)
  app.component('input-checkbox', inputCheckbox)
  app.component('sms-clarification', smsClarification)
  app.component('accept-legal-documents', acceptLegalDocuments)
  app.component('toastr', toastr)
  app.component('send-new-sms', sendNewSms)
  app.component('password-checker', passwordChecker)
  app.component('app-modal', appModal)
  app.component('cookie-banner', cookieBanner)

  const requireDirective = require.context('@directives', true, /\.(js)$/)
  const requirePlugin = require.context('@plugins', true, /\.(js)$/)

  requireDirective.keys().forEach((fileName) => {
    const directiveConfig = requireDirective(fileName)

    const directiveName = kebabCase(fileName.split('/').pop().replace(/\.\w+$/, ''))

    app.directive(
      directiveName,
      directiveConfig.default || directiveConfig
    )
  })

  requirePlugin.keys().forEach((fileName) => {
    const plugin = requirePlugin(fileName)

    app.use(plugin.default || plugin)
  })

  app.directive('maska', vMaska)
}
